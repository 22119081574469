<template>
  <CContainer class="d-flex align-items-center min-vh-100 page404">
    <CRow class="w-100 justify-content-center">
      <CCol md="12">
        <!--<div class="text-left mt-4" style="font-size: 18px">
          ページが見つかりませんでした。
        </div>-->
        <div class="w-100">
          <div class="clearfix">
            <div
              class="text-center font-weight-bold text-center mt-5"
              style="font-size: 25px"
            >
              <p>道に迷ったのですか？</p>
              <p>ではひと休みしませんか？</p>
              <p>即席ダンスパーティの始まりです！</p>
            </div>
            <div class="d-flex justify-content-center">
              <div>
                <img
                  v-if="page_404"
                  :src="page_404"
                  width="100%"
                  height="100%"
                  style="object-fit: contain; width: 100%; height: 100%"
                />
                <img
                  v-else
                  src="@/assets/img/no-image.jpg"
                  alt=""
                  width="100%"
                  height="100%"
                  style="object-fit: contain; width: 100%; height: 100%"
                />
              </div>
            </div>
            <div
              class="text-center font-weight-bold text-center mb-5"
              style="font-size: 25px"
            >
              <p>ダンスパーティは終わりましたか？</p>
              <p>
                それでは、<span>
                  <router-link
                    :to="{
                      name: $route.params.shopId ? 'store' : 'store domain',
                    }"
                    class="link-home-page"
                    style="color: rgb(60 75 100); text-decoration: none"
                  >
                    トップページ
                  </router-link>
                </span>
                に戻りましょう。
              </p>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";

export default {
  name: "Page404",
  data() {
    return {
      dataShop: [],
      page_404: null,
      homepage: window.location.origin,
      shop_id: this.$route.params.shopId,
    };
  },
  created() {
    this.getListSettingShopUser({
      domain: Constants.DOMAIN,
      shop_id: this.$route.params.shopId,
    });
  },
  computed: {
    ...mapGetters(["ListSettingShop"]),
  },
  watch: {
    ListSettingShop() {
      this.dataShop = this.ListSettingShop;
      if (
        this.dataShop.length > 0 &&
        this.dataShop[0].page_404 != null &&
        this.dataShop[0].page_404 != ""
      ) {
        this.page_404 = Constants.URL_BE + "/" + this.dataShop[0].page_404;
      }
    },
  },
  methods: {
    ...mapActions({ getListSettingShopUser: "getListSettingShopUser" }),
  },
};
</script>
<style lang="scss" scoped>
.link-home-page:hover {
  color: #aaa !important;
}
</style>
